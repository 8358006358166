import React from 'react'
import { render } from 'react-dom'
import App from './App'

// Parcel bundler: Enable hot-module-reloading
// Applies in development mode only
if (module.hot) {
  module.hot.accept()
}

// Mount the app
render(<App />, document.getElementById('root'))
