import React from 'react'
import PropTypes from 'prop-types'
import { countries } from 'country-data'

const sortedCountries = countries.all
  .filter(({ alpha2 }) => alpha2)
  .sort((a, b) => {
    var nameA = a.name.toUpperCase()
    var nameB = b.name.toUpperCase()
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })

/** A self-contained country selector, using locally-packaged country data.
 * Adds about 100kb to the bundled app size ;-)
 */
const CountrySelector = ({ value, onChange, disabled = false, inputID }) => {
  const onSelectChange = onChange != null ? (e) => onChange(e.target.value) : undefined
  return (
    <select value={value || ''} id={inputID} onChange={onSelectChange} disabled={disabled}>
      {!value && <option value=''>Select country:</option> /* Empty option only when none selected */}
      {sortedCountries.map(({ alpha2, name }) => (
        <option value={alpha2} key={`${alpha2}-${name}`}>
          {name}
        </option>
      ))}
    </select>
  )
}

CountrySelector.propTypes = {
  // 3-letter CountryCode
  value: PropTypes.string,
  // Function of type CountryCode -> ()
  onChange: PropTypes.func.isRequired,
  // If true, input does not accept changes from user
  disabled: PropTypes.bool,
  // If specified, actual form input will receive this ID.
  // Useful e.g. to wire up a label which focuses the input when clicked.
  inputID: PropTypes.string,
}

export default CountrySelector
