/** An empty "starting point" template for a sale */
const blankSample = {
  card: {
    expiry: {
      year: 23,
      month: 7,
    },
    pan: '4000000000000002',
    cvv: '723',
    name_on_card: 'J R Smith',
  },
  card_holder: {
    name: {
      prefix: 'Mr',
      first: 'John',
      last: 'Smith',
    },
    date_of_birth: '1968-09-11',
    gender: 'male',
    contact: {
      email: 'john@example.com',
      phone: '+27 82 123 4567',
    },
    billing_address: {
      line1: 'Rua Igreja 67',
      line2: '',
      postal_code: '4760-699',
      city: 'Gavião',
      region: 'Braga',
      country: 'PT',
    },
  },
  order: {
    total: {
      amount: 5.41,
      currency: 'EUR',
    },
    ref: 'DEMO/',
    invoice: {
      ref: 'Invoice 1234',
      date: '2020-09-17',
    },
  },
  customer: {
    ref: 'user:5153',
  },
  device: {
    ip: '169.1.43.61',
    http_headers: {
      accept: '',
      accept_language: '',
      user_agent: '',
    },
  },
  callback_url: '',
  customer_destination_url: '',
}

export default blankSample
