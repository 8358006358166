import React from 'react'
import { NavLink } from 'react-router-dom'

/** Header writes a standard top header.
 * Either renders provided children in the top-left,
 * otherwise the default branding.
 */
const Header = ({ children }) => (
  <header className='app'>
    {children || (
      <div className='brand'>
        <div className='logo'></div>
        <h2>Merchant Demonstrator </h2>
      </div>
    )}
    <nav>
      <ul>
        <li>
          <NavLink to='/sale/new'>
            New <span className='exp'>Sale</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/transactions/all'>
            <span className='exp'>Transaction</span> History
          </NavLink>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
