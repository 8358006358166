import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

// High-level components (routes)
import SaleRequest from './SaleRequest'
import SaleTransaction from './SaleTransaction'

const PagoraTheme = React.lazy(() => import('./style/themes/pagora/pagoraTheme'))
const CleanPayTheme = React.lazy(() => import('./style/themes/cleanpay/cleanpayTheme'))

const ThemeSelector = ({ children }) => {
  const loadPagoraTheme = window.location.href.includes('pagora')
  const loadCleanPayTheme = window.location.href.includes('cleanpay') || window.location.href.includes('localhost')
  return (
    <>
      <React.Suspense fallback={<></>}>
        {loadPagoraTheme && <PagoraTheme />}
        {loadCleanPayTheme && <CleanPayTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

/** Outer shell of the app - hosts URL routes to top-level components */
const App = () => (
  <ThemeSelector>
    <Router>
      <Switch>
        <Route exact path='/sale/new' component={SaleRequest} />
        <Route exact path='/transaction/:id' component={SaleTransaction} />
        {/* Default landing page */}
        <Redirect to='/sale/new' />
      </Switch>
    </Router>
  </ThemeSelector>
)

export default App
