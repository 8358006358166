/** Base URL of the `mock-merchant` API (HTTP Server) to call.
Note: Logic carried across exactly as per older mock-merchant/web for
demo to not break things - TODO: Upgrade to be driven by environment config */
const API_BASE_URL =
  window.location.hostname === 'localhost' ? 'http://localhost:9090' : `https://${window.location.hostname}`

/** POST a new sale. The input is a standard Cosmonaut Sale request, but this
 * is posted to the mock-merchant/http server for server-side enrichment and
 * handling of callbacks from cosmo etc.
 * Reply type is a standard Cosmonaut `SaleTransactionStatus` with:
 * status, at, transaction_id, order_ref, failure_code, failure_code_minor, total_requested,
 * total_settled, score, cust_verification_required
 */
export const postSale = (req) =>
  fetch(`${API_BASE_URL}/payfwd`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  }).then((r) => r.json())

/** GET a previous transaction by ID. Calls the `mock-merchant`
 * HTTP endpoint, which is reponsible for authenticating and
 * retrieving from Cosmonaut - this front-end never directly
 * interacts with Cosmonaut for auth reasons.
 */
export const getSale = (transaction_id) =>
  fetch(`${API_BASE_URL}/getfwd/${transaction_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((r) => r.json())

/** GET a previous RAW transaction by ID. Calls the `mock-merchant`
 * HTTP endpoint, which is reponsible for authenticating and
 * retrieving from Cosmonaut - this front-end never directly
 * interacts with Cosmonaut for auth reasons.
 */
export const getSaleRaw = (transaction_id) =>
  fetch(`${API_BASE_URL}/getfwd/raw/${transaction_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((r) => r.json())
