import React from 'react'
import classnames from 'classnames'
import { ResponsiveContainer, RadialBarChart, RadialBar, Legend, Label } from 'recharts'

/** Given the adjustment factor of a given category, and the total adjustment,
 * allocates "good", "ok" or "bad" based on the impact on the total adjustment.
 * This is because we don't have any categorization from the back-end.
 */
const classifyCategory = (adj, totalAdj) => {
  if (adj == 0 || totalAdj == 0) {
    return 'good'
  }
  const percent = (Math.abs(adj) / Math.abs(totalAdj)) * 100
  return percent > 25 ? 'bad' : percent > 5 ? 'ok' : 'good'
}

const ScoringResultsSummary = ({ grade, result = 0, max = 0, categories = [] }) => {
  const totalAdj = max - result
  /* Score data */
  const data = categories.map(({ key, adj }) => {
    const cls = classifyCategory(adj, totalAdj)
    return {
      name: key,
      uv: Math.abs(adj),
      pv: result,
      fill: cls === 'good' ? '#98E798' : cls === 'ok' ? '#E4E798' : '#E79899',
    }
  })
  data.push({
    name: `Score: ${result}`,
    uv: result,
    pv: result,
    fill:
      grade === 'pass_excellent'
        ? '#63DC1E'
        : grade === 'pass_good'
        ? '#B3DC1E'
        : grade === 'requires_review'
        ? '#DCC41E'
        : '#DC391E',
  })
  data.push({
    name: `Of:   ${max}`,
    uv: max,
    pv: max,
    fill: '#EEE',
  })

  return (
    <div className='score-result-summary'>
      {grade && max && (
        <div className={classnames({ grade: true, [grade]: true })}>
          <RadialBarChart
            width={400}
            height={200}
            cx={120}
            cy={100}
            innerRadius={20}
            outerRadius={100}
            barSize={8}
            data={data}
          >
            <RadialBar
              minAngle={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              background
              clockWise
              dataKey='uv'
            >
              <Label value={'Score ' + result + ' / ' + max} position='center' />
            </RadialBar>
            <Legend
              iconSize={10}
              iconType='circle'
              width={150}
              height={100}
              layout='vertical'
              verticalAlign='middle'
              wrapperStyle={{
                top: '2rem',
                left: 250,
              }}
            />
          </RadialBarChart>
        </div>
      )}
      {categories && categories.length > 0 ? (
        <div className='categories'>
          {categories.map(({ key, adj }) => (
            <div key={key} className={classnames({ category: true, [classifyCategory(adj, totalAdj)]: true })}>
              <div className='key'>{key}</div>
              <div className='adj'>{adj}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default ScoringResultsSummary
