import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import ScoringResultsSummary from './ScoringResultsSummary'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { getSaleRaw } from './api'

const SaleTransactionDone = ({ txn = {} }) => {
  // Retrieve 'raw' transaction with internal, detailed fields. Not available
  // to standard merchants
  const [txnRaw, setTxnRaw] = useState(undefined)
  const [txnRawError, setTxnRawError] = useState(undefined)
  const [txnLoading, setTxnRawLoading] = useState(false)

  // Fetches the 'raw' transaction + manages state around the fetch
  const fetchRaw = async () => {
    try {
      setTxnRawLoading(true)
      const raw = await getSaleRaw(txn.transaction_id)
      if (raw.CustomerDeviceRawData) {
        try {
          raw.CustomerDeviceRawData = JSON.parse(raw.CustomerDeviceRawData)
        } catch (e) {
          console.warn('Customer device raw data is not JSON', e)
        }
      }
      setTxnRaw(raw)
      setTxnRawLoading(false)
      setTxnRawError(undefined)
      console.log('Transaction raw details', raw)
    } catch (e) {
      window.alert('There was a problem fetching details about this transaction, please try again')
      setTxnRawError(e)
      setTxnRawLoading(false)
    }
  }

  // FIXME: On mount fetch raw transaction
  // useEffect(() => {
  //   fetchRaw()
  // }, [txn.transaction_id])

  // TODO: Make map of scoring rules details, pass to results component

  const [showCode, setShowCode] = useState('response') // 'deviceraw', 'metaraw', 'scoreraw'
  const code =
    showCode === 'response'
      ? txn
      : showCode === 'deviceraw'
      ? txnRaw.CustomerDeviceRawData
      : showCode === 'metaraw'
      ? txnRaw.Meta
      : showCode === 'scoreraw'
      ? txnRaw.Score
      : {}
  return (
    <div className='complete'>
      <Helmet>
        <title>Transaction {txn.status && txn.status.toUpperCase()}: Cosmonaut Merchant Demonstrator</title>
      </Helmet>
      {txn.score && <ScoringResultsSummary {...txn.score} />}
      <div className='response'>
        <div className='gui'>
          <h1>Transaction Complete: {txn.status && txn.status.toUpperCase()}</h1>
          <table>
            <tbody>
              <tr className='transaction_id'>
                <td className='key'>Transaction ID</td>
                <td className='val'>{txn.transaction_id}</td>
              </tr>
              <tr className='order_ref'>
                <td className='key'>Merchant order ref</td>
                <td className='val'>{txn.order_ref}</td>
              </tr>
              {txn.total_requested && (
                <tr className='total_requested'>
                  <td className='key'>Total requested</td>
                  <td className='val'>
                    <Money {...txn.total_requested} />
                  </td>
                </tr>
              )}
              {txn.total_settled && (
                <tr className='total_settled'>
                  <td className='key'>Total settled</td>
                  <td className='val'>
                    <Money {...txn.total_settled} />
                  </td>
                </tr>
              )}
              {txn.at && (
                <tr className='psp_transaction_at'>
                  <td className='key'>Date/time</td>
                  <td className='val'>{txn.at}</td>
                </tr>
              )}

              {txnRaw && txnRaw.PSPTransactionID && (
                <tr className='psp_transaction_id'>
                  <td className='key'>Upstream PSP Transaction ID</td>
                  <td className='val'>{txnRaw.PSPTransactionID}</td>
                </tr>
              )}
              {txn.failure_code && (
                <tr className='failure_code error'>
                  <td className='key'>Failure code</td>
                  <td className='val'>{txn.failure_code || '0'}</td>
                </tr>
              )}
              {txn.failure_code_minor && (
                <tr className='failure_code_minor error'>
                  <td className='key'>Failure code (minor)</td>
                  <td className='val'>{txn.failure_code_minor || '0'}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='code'>
          {txnRaw && (
            <div className='field selector'>
              <select id='codemode' value={showCode} onChange={({ target: { value } }) => setShowCode(value)}>
                <option value='response'>Show JSON: Merchant Response</option>
                <option value='deviceraw'>Show JSON: Customer Device Fingerprint</option>
                <option value='metaraw'>Show JSON: Transaction Metadata</option>
                <option value='scoreraw'>Show JSON: Scoring rules details</option>
              </select>
            </div>
          )}
          <JSONInput
            placeholder={code}
            locale={locale}
            theme='light_mitsuketa_tribute'
            colors={{ background: '#f3f3f3' }}
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </div>
  )
}

const Money = ({ amount, currency }) => (
  <span className='money'>
    <strong className='currency'>{currency}</strong> <span className='amount'>{amount}</span>
  </span>
)

export default SaleTransactionDone
