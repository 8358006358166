import React, { useState } from 'react'
import { debounce } from 'debounce'
import Cards from 'react-credit-cards'
import { NavLink } from 'react-router-dom'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { path, pathOr, assocPath, split } from 'ramda'
import { postSale } from './api'
import { useHistory } from 'react-router-dom'
import saleRequestTemplate from './sale-request-template'
import Header from './Header'
import CountrySelector from './CountrySelector'
import Helmet from 'react-helmet'

/** Converts Cosmo structured card expiry to MM/YY text */
const toCardExpiryString = ({ year = 0, month = 0 }) => {
  if (month && year) {
    const yearS = (year < 10 ? '0' : '') + year
    const monthS = (month < 10 ? '0' : '') + month
    return monthS + '/' + yearS
  }
  return ''
}

/** Converts from MM/YY text to { month :: Number, year :: Number} */
const fromCardExpiryString = (value) => {
  const [monthS, yearS] = split('/', value)
  if (monthS && yearS) {
    return {
      month: Math.min(Number(monthS), 12), // Up to 12
      year: Number(yearS.substring(yearS.length - 2)), // Last 2 digits
    }
  }
  return { month: null, year: null }
}

const saveDraftReq = debounce((req) => {
  if (typeof req === 'object') {
    console.log('Saving draft')
    window.localStorage.setItem('sale-request', JSON.stringify(req))
    window.localStorage.setItem('i18nextLng', req.card_holder.billing_address.country)
  }
}, 500)

const retrieveDraftReq = () => {
  const draftJSON = window.localStorage.getItem('sale-request')
  if (draftJSON) {
    try {
      return JSON.parse(draftJSON)
    } catch (e) {
      console.error(`Failed to load draft request from local storage`, e)
    }
  }
}

const clearDraftReq = () => {
  window.localStorage.removeItem('sale-request')
}

// Attempts to replace the order ref with the current timestamp encoded into a base-36 string.
// This could be improved with a hash or bit shifting to mask the progression.
const trySetAutoOrderRef = (req) => {
  if (req && req.order && req.order.ref && (req.order.ref.startsWith('DEMO/') || req.order.ref == 'SBL523/2')) {
    const encodedTimestamp = new Date().valueOf().toString(36).toUpperCase()
    req.order.ref = `DEMO/${encodedTimestamp}`
  }

  return req
}

// Input fields with masks, see:
// https://github.com/uNmAnNeR/imaskjs/tree/master/packages/react-imask
// https://codepen.io/quinlo/pen/YONMEa

/** Responsible for allowing the user to play the role of customer,
 * and edit + submit a Cosmo Sale request.
 * TODO: Upon submission and receipt of reply, "seed" reply data by storing
 * in local storage and redirect to transaction status.
 */
const SaleRequest = () => {
  // Stores a Cosmo Transact Sale Request
  const [req, unsafeSetReq] = useState(trySetAutoOrderRef(retrieveDraftReq() || saleRequestTemplate))

  const setReq = (r) => {
    unsafeSetReq(r || {}) // Otherwise <Cards> component fucks out
    saveDraftReq(r) // Store as draft for future (debounced)
  }

  // Allows a user to reset to the standard template
  const resetReq = () => {
    unsafeSetReq(trySetAutoOrderRef(saleRequestTemplate))
    clearDraftReq()
  }

  // Tracks which card field we're currently focusing on, for <Cards> component
  // especially the nice flip-over effect for CVV
  const [cardFieldFocus, setCardFieldFocus] = useState(undefined) // name|number|expiry|cvc

  // Tracks whether we are busy editing the card expiry date, in order
  // to switch the field between controlled and uncontrolled mode
  const [editingExpiry, setEditingExpiry] = useState(false)

  // Posts the sale, and handles the reply: Caches the reply in local
  // storage, and routes to the Transaction view, which takes over the
  // responsibility of tracking transaction progress and rendering the result.
  const [reqError, setReqError] = useState(undefined)
  const history = useHistory()
  const submit = async () => {
    try {
      const response = await postSale(req) // TODO: Error handling? (HTTP error codes)
      if (response && response.transaction_id) {
        // Cache in Local Storage (for immediate display while transaction view fetches updates)
        window.localStorage.setItem(response.transaction_id, JSON.stringify(response))
        history.push(`/transaction/${response.transaction_id}`)
      }
    } catch (e) {
      setReqError(e)
      console.warn('POST Sale failed', e)
      // TODO: Update UI to render, for now we just render an alert
      window.alert('Something went wrong with sending that request, please try again')
    }
  }

  return (
    <div className='sale-request'>
      <Helmet>
        <title>New Sale : Cosmonaut Merchant Demonstrator</title>
      </Helmet>
      <Header />
      {/* TODO: Visual error handling, i.e. when submit fails */}
      <section className='editor'>
        <div className='gui'>
          {/* Card display component docs: https://github.com/amarofashion/react-credit-cards */}
          <Cards
            number={pathOr('', ['card', 'pan'], req)}
            name={pathOr('', ['card', 'name_on_card'], req)}
            expiry={toCardExpiryString(pathOr({}, ['card', 'expiry'], req))}
            cvc={pathOr('', ['card', 'cvv'], req)}
            focused={cardFieldFocus}
          />
          <section className='card-details'>
            <div className='field pan'>
              <label htmlFor='pan'>Card number</label>
              <input
                id='pan'
                type='tel'
                value={pathOr('', ['card', 'pan'], req)}
                onFocus={() => setCardFieldFocus('number')}
                onChange={({ target: { value } }) => setReq(assocPath(['card', 'pan'], value.trim(), req))}
                onBlur={() => setCardFieldFocus(undefined)}
              />
            </div>
            <div className='field name_on_card'>
              <label htmlFor='pan'>Name</label>
              <input
                id='name_on_card'
                type='text'
                value={pathOr('', ['card', 'name_on_card'], req)}
                onFocus={() => setCardFieldFocus('name')}
                onChange={({ target: { value } }) => setReq(assocPath(['card', 'name_on_card'], value, req))}
                onBlur={() => setCardFieldFocus(undefined)}
              />
            </div>
            <div className='fieldgroup'>
              <div className='field expiry'>
                <label htmlFor='expiry'>Expiry (MM/YY)</label>
                {/* Note: This unusual field is treated as a controlled component while NOT focused, e.g. to receive
                    updates from elsewhere like JSON editing, but not as a controlled component while editing, to allow
                    for the input of the MM/YY value which is only later converted to Cosmo { year, month } structure.*/}
                <input
                  id='expiry'
                  type='text'
                  value={editingExpiry ? undefined : toCardExpiryString(pathOr({}, ['card', 'expiry'], req))}
                  defaultValue={editingExpiry ? toCardExpiryString(pathOr({}, ['card', 'expiry'], req)) : undefined}
                  onFocus={() => {
                    setEditingExpiry(true)
                    setCardFieldFocus('expiry')
                  }}
                  onChange={() => {
                    /* Makes React Happy, but not needed */
                  }}
                  onBlur={({ target: { value } }) => {
                    setReq(assocPath(['card', 'expiry'], fromCardExpiryString(value), req))
                    setEditingExpiry(false)
                    setCardFieldFocus(undefined)
                  }}
                />
              </div>
              <div className='field cvv'>
                <label htmlFor='cvv'>CVV</label>
                <input
                  id='cvv'
                  type='tel'
                  value={pathOr('', ['card', 'cvv'], req)}
                  onFocus={() => setCardFieldFocus('cvc')}
                  onChange={({ target: { value } }) => setReq(assocPath(['card', 'cvv'], value, req))}
                  onBlur={() => setCardFieldFocus(undefined)}
                />
              </div>
            </div>
          </section>
          <section className='order'>
            <h2>Order details</h2>
            <div className='fieldgroup total'>
              <div className='field amount'>
                <label htmlFor='amount'>Total</label>
                <input
                  id='amount'
                  type='number'
                  value={pathOr('', ['order', 'total', 'amount'], req)}
                  onChange={({ target: { value } }) =>
                    setReq(assocPath(['order', 'total', 'amount'], Number(value), req))
                  }
                />
              </div>
              <div className='field currency'>
                <label htmlFor='currency'>Currency</label>
                <input
                  id='currency'
                  type='text'
                  value={pathOr('', ['order', 'total', 'currency'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['order', 'total', 'currency'], value, req))}
                />
              </div>
            </div>
            <div className='field ref'>
              <label htmlFor='ref'>Merchant ref</label>
              <input
                id='ref'
                type='text'
                value={pathOr('', ['order', 'ref'], req)}
                onChange={({ target: { value } }) => setReq(assocPath(['order', 'ref'], value, req))}
              />
            </div>
          </section>
          <section className='card-holder'>
            <h2>Card holder details</h2>
            <div className='fieldgroup name'>
              <div className='field prefix'>
                <label htmlFor='holder_first'>Name</label>
                <input
                  id='prefix'
                  placeholder='Mr'
                  type='text'
                  value={pathOr('', ['card_holder', 'name', 'prefix'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'name', 'prefix'], value, req))}
                />
              </div>
              <div className='field first'>
                <input
                  id='first'
                  type='text'
                  placeholder='First name'
                  value={pathOr('', ['card_holder', 'name', 'first'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'name', 'first'], value, req))}
                />
              </div>
              <div className='field last'>
                <input
                  id='last'
                  type='text'
                  placeholder='Last name'
                  value={pathOr('', ['card_holder', 'name', 'last'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'name', 'last'], value, req))}
                />
              </div>
            </div>
            <div className='field email'>
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                value={pathOr('', ['card_holder', 'contact', 'email'], req)}
                onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'contact', 'email'], value, req))}
              />
            </div>
            <div className='field phone'>
              <label htmlFor='phone'>Phone</label>
              <input
                id='phone'
                type='tel'
                value={pathOr('', ['card_holder', 'contact', 'phone'], req)}
                onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'contact', 'phone'], value, req))}
              />
            </div>
            <div className='fieldgroup'>
              <div className='field birth'>
                <label htmlFor='holder_birth'>Date of Birth</label>
                <input
                  id='holder_birth'
                  type='date'
                  value={pathOr('', ['card_holder', 'date_of_birth'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'date_of_birth'], value, req))}
                />
              </div>
              <div className='field gender'>
                <label htmlFor='holder_gender'>Gender</label>
                <select
                  id='holder_gender'
                  value={pathOr('', ['card_holder', 'gender'], req)}
                  onChange={({ target: { value } }) => setReq(assocPath(['card_holder', 'gender'], value, req))}
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </select>
              </div>
            </div>
            <h2>Billing Address</h2>
            <div className='field line1'>
              <label htmlFor='line1'>Line 1</label>
              <input
                id='line1'
                type='text'
                value={pathOr('', ['card_holder', 'billing_address', 'line1'], req)}
                onChange={({ target: { value } }) =>
                  setReq(assocPath(['card_holder', 'billing_address', 'line1'], value, req))
                }
              />
            </div>
            <div className='field line2'>
              <label htmlFor='line2'>Line 2</label>
              <input
                id='line2'
                type='text'
                value={pathOr('', ['card_holder', 'billing_address', 'line2'], req)}
                onChange={({ target: { value } }) =>
                  setReq(assocPath(['card_holder', 'billing_address', 'line2'], value, req))
                }
              />
            </div>
            <div className='field postal'>
              <label htmlFor='postal_code'>Postal Code</label>
              <input
                id='postal_code'
                type='text'
                value={pathOr('', ['card_holder', 'billing_address', 'postal_code'], req)}
                onChange={({ target: { value } }) =>
                  setReq(assocPath(['card_holder', 'billing_address', 'postal_code'], value, req))
                }
              />
            </div>
            <div className='field city'>
              <label htmlFor='city'>City</label>
              <input
                id='city'
                type='text'
                value={pathOr('', ['card_holder', 'billing_address', 'city'], req)}
                onChange={({ target: { value } }) =>
                  setReq(assocPath(['card_holder', 'billing_address', 'city'], value, req))
                }
              />
            </div>
            <div className='field region'>
              <label htmlFor='region'>Region</label>
              <input
                id='region'
                type='text'
                value={pathOr('', ['card_holder', 'billing_address', 'region'], req)}
                onChange={({ target: { value } }) =>
                  setReq(assocPath(['card_holder', 'billing_address', 'region'], value, req))
                }
              />
            </div>
            <div className='field country'>
              <label htmlFor='country'>Country</label>
              <CountrySelector
                inputID='country'
                value={pathOr('', ['card_holder', 'billing_address', 'country'], req)}
                onChange={(value) => setReq(assocPath(['card_holder', 'billing_address', 'country'], value, req))}
              />
            </div>
          </section>
        </div>
        <div className='code'>
          <JSONInput
            placeholder={req}
            locale={locale}
            theme='light_mitsuketa_tribute'
            colors={{ background: '#f3f3f3' }}
            width='100%'
            height='100%'
            onChange={({ jsObject }) => setReq(jsObject)}
          />
        </div>
      </section>
      <footer className='actions'>
        <button onClick={resetReq} className='reset'>
          Reset
        </button>
        <button onClick={submit}>Submit</button>
      </footer>
    </div>
  )
}

export default SaleRequest
