import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useInterval } from './util/use-interval'
import { getSale } from './api'
import Header from './Header'
import Helmet from 'react-helmet'

// Per-status views
import SaleTransactionCustomerVerification from './SaleTransactionCustomerVerification'
import SaleTransactionDone from './SaleTransactionDone'

// How often to poll while transaction is not complete. ms.
const INTERVAL_PENDING = 5000
// How often to poll when transaction indicates a 'final' status. ms
const INTERVAL_FINAL = 5000

/** Returns true if the given transaction is considered final */
const hasFinalStatus = ({ status }) => status === 'ok' || status === 'failed' || status === 'rejected'

/** Responsible for retrieving, monitoring, and displaying details of a
 * Sale Transaction (where the `transaction_id` is known). Depending on the
 * status, displays one of several high-level components.
 * TODO: On first load, see if we have a pre-seeded reply in local storage,
 * which we load into state while we dispatch an async fetch of the updated status.
 */
const SaleTransaction = () => {
  // URL parameters
  const { id } = useParams()

  // Track transaction in state (official, merchant-facing response)
  const [txn, setTxn] = useState(undefined) // SaleTransactionStatus
  const [txnErr, setTxnErr] = useState(undefined) // Error fetching txn
  const [txnLoading, setTxnLoading] = useState(false) // Busy loading transaction - only when NOT polling
  const [txnFetchInterval, setTxnFetchInterval] = useState(INTERVAL_PENDING) // ms between fetch attempts

  // See if there is cached version in local storage, seed display (also remove)
  useEffect(() => {
    const txnJSON = window.localStorage.getItem(id)
    if (txnJSON) {
      try {
        const txnObj = JSON.parse(txnJSON)
        setTxn(txnObj)
        // Clear from cache, this is a one-time optimisation
        window.localStorage.removeItem(id)
      } catch (e) {
        setTxnErr(e)
        console.error(`Failed to load transaction ${id} from local storage`, e)
      }
    }
  }, [id])

  const fetchTxn = async () => {
    try {
      if (!txn) {
        setTxnLoading(true)
      }
      const fetchedTxn = await getSale(id)
      setTxn(fetchedTxn)
      setTxnLoading(false)
      setTxnErr(null)

      // Depending on whether status is final, adjust the callback interval
      if (hasFinalStatus(fetchedTxn)) {
        setTxnFetchInterval(INTERVAL_FINAL)
      }
    } catch (e) {
      setTxnLoading(false)
      setTxnErr(e)
      console.error('Failed to fetch transaction', e)
    }
  }

  // Set up polling: Continue fetching e.g. every 2 seconds while in non-final status
  useInterval(
    async () => {
      console.log(`Polling for ${id}`)
      fetchTxn()
    },
    txnFetchInterval,
    true // Immediate. False will do first fetch *after* delay
  )
  // TODO: Open web socket to mock merchant to get updates from callback, redirect...
  return (
    <div className='sale-transaction'>
      {/* TODO: Status header */}
      <Header />
      {txn && console.log('Status', txn.status)}
      {/* TODO: Error banner */}
      {txnLoading && <Loading />}
      {txn && txn.status === 'cust_verification_required' && <SaleTransactionCustomerVerification txn={txn} />}
      {txn && hasFinalStatus(txn) && <SaleTransactionDone txn={txn} />}
    </div>
  )
}

const Loading = () => (
  <div className='loading'>
    <Helmet>
      <title>Please wait...</title>
    </Helmet>
    <h2>Loading Transaction...</h2>
  </div>
)

export default SaleTransaction
